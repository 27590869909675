.child-safety-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: Arial, sans-serif;
}

.child-safety-container h1 {
  color: #333;
  margin-bottom: 2rem;
  text-align: center;
  font-size: 2rem;
}

.child-safety-content {
  line-height: 1.6;
}

.child-safety-content p {
  margin-bottom: 2rem;
  font-size: 1.1rem;
  color: #444;
}

.safety-section {
  margin-bottom: 2.5rem;
  padding: 1.5rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.safety-section h2 {
  color: #2c3e50;
  margin-bottom: 1rem;
  font-size: 1.3rem;
}

.safety-section ul {
  list-style-type: none;
  padding-left: 0;
}

.safety-section li {
  margin-bottom: 0.8rem;
  padding-left: 1.5rem;
  position: relative;
  font-size: 1rem;
}

.safety-section li:before {
  content: "•";
  color: #3498db;
  position: absolute;
  left: 0;
}

.safety-section a {
  color: #3498db;
  text-decoration: none;
  word-break: break-word;
}

.safety-section a:hover {
  text-decoration: underline;
}

/* Tablet için responsive tasarım */
@media (max-width: 1024px) {
  .child-safety-container {
    padding: 1.5rem;
  }
  
  .child-safety-container h1 {
    font-size: 1.8rem;
  }
  
  .safety-section {
    padding: 1.2rem;
  }
}

/* Mobil için responsive tasarım */
@media (max-width: 768px) {
  .child-safety-container {
    padding: 1rem;
    margin-top: 2rem;
  }
  
  .child-safety-container h1 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .child-safety-content p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .safety-section {
    padding: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .safety-section h2 {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
  }
  
  .safety-section li {
    font-size: 0.95rem;
    margin-bottom: 0.6rem;
    padding-left: 1.2rem;
  }
}

/* Küçük mobil cihazlar için responsive tasarım */
@media (max-width: 480px) {
  .child-safety-container {
    padding: 0.8rem;
    margin-top: 1.5rem;
  }
  
  .child-safety-container h1 {
    font-size: 1.3rem;
  }
  
  .safety-section {
    padding: 0.8rem;
  }
  
  .safety-section h2 {
    font-size: 1.1rem;
  }
  
  .safety-section li {
    font-size: 0.9rem;
    padding-left: 1rem;
  }
} 