.privacy-container {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  /* color: #333; */
}

.privacy-container h2 {
  font-size: 24px;
  /* color: #333; */
  text-align: left;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.privacy-container h3 {
  font-size: 20px;
  /* color: #333; */
  text-align: left;
  margin-top: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.privacy-container h4 {
  font-size: 16px;
  text-align: left;
}

.privacy-container p {
  font-size: 16px;
  line-height: 1.6;
  /* color: #555; */
  margin-bottom: 15px;
}

.privacy-container ul {
  margin-left: 20px;
  margin-bottom: 20px;
}

.privacy-container ul li {
  font-size: 16px;
  line-height: 1.6;
  /* color: #555; */
  margin-bottom: 10px;
}

.privacy-container a {
  color: #007bff;
  text-decoration: none;
}

.privacy-container a:hover {
  text-decoration: underline;
}
p {
  text-align: left;
}
li {
  text-align: left;
}
